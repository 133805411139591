import styled from 'styled-components'
import media from "styled-media-query"
//import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FiClock } from "react-icons/fi";

import * as V from '../../styles/variables'

const Icon = `
  padding-top: .13rem;

  ${media.lessThan("medium")`
    padding-top: .13rem;
  `}
`

export const PostItemLink = styled(AniLink)`
  color: var(--texts);
  display: flex;
  text-decoration: none;
  margin-bottom: 1.5rem;

  body#grid & {
    background-color: var(--background);
  }

  &:hover {
    color: var(--highlightColor);
  }
`

/*export const PostItemWrapper = styled.section`
  align-items: center;
  border-bottom: 1px solid var(--borders);
  display: flex;
  padding: 2rem 3rem;
  width: 100%;

  body#grid & {
    border: none;
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: center;
  }

  ${media.lessThan("medium")`
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem 1rem;
  `}
`*/

export const PostItemWrapper = styled.div`
  background-color: var(--primaryColor);
  align-items: center;
  //border: ${V.Border.box};
  border-radius: ${V.BorderRadius.box};
  box-shadow: ${V.BoxShadow.box};
  color: var(--shellText);
  display: flex;
  height: 100%;
  width: 100%;
  //margin-left: 25%;
  min-height: 15rem;
  padding: ${V.Space.sm};
  position: relative;
  will-change: border-color;
  ${media.greaterThan('medium')`
    padding: ${V.Space.default};
  `}
  &:after,
  &:before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform ${V.Transition.default};
  }
  &:before {
    border-radius: ${V.BorderRadius.box};
    border-left: 1px solid var(--menuHover);
    border-right: 1px solid var(--menuHover);
    transform: scaleY(0);
  }
  &:after {
    border-radius: ${V.BorderRadius.box};
    border-bottom: 1px solid var(--menuHover);
    border-top: 1px solid var(--menuHover);
    transform: scaleX(0);
  }
  &:hover { 
    &:before {
      border-radius: ${V.BorderRadius.box};
      transform: scaleY(1)
    }
    &:after {
      border-radius: ${V.BorderRadius.box};
      transform: scaleX(1)
    }
  }

  body#grid & {
    border: none;
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: center;
  }

  ${media.lessThan("medium")`
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem 1rem;
  `}
`

export const PostClock = styled(FiClock)`
  ${Icon}
  display:inline-block;
  color: var(--shellText);

  /*selector for hover in child too*/
  ${PostItemWrapper} & {
    transition: color 0.3s;
  }
  ${PostItemWrapper}:hover & {
    color: var(--menuHover);
  }
`

export const PostItemTag = styled.div`
  align-items: center;
  min-height: 90px;
  min-width: 90px;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: .2rem .5rem;
  background: ${props => props.background ? props.background : 'var(--highlight)'};
  //border-radius: 50%;
  clip-path: polygon(0 20%, 100% 20%, 55% 80%, 0% 80%); //saplike polygon
  border-radius: .25rem .1rem .1rem .25rem;
  color: #fff;
  display: flex;
  justify-content: left;
  text-shadow: 0px 0px 7px #00000099; 
  font-size: 1.7rem;
  letter-spacing: .1rem;
  font-weight: 700;
  text-transform: uppercase;

  ${media.lessThan("medium")`
    border-radius: 0;
    margin-left: 0%;
    font-size: 1rem;
    min-height: auto;
    min-width: auto;
    padding: .2rem .5rem;
    margin-bottom: .7rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  `}

  body#grid & {
    margin-bottom: 1.5rem;
  }
`

export const PostItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;

  ${media.lessThan("medium")`
    margin: 0;
  `}

  body#grid & {
    line-height: 1.1;
    margin: 0.8rem 0;
  }
  
  /*selector for hover in child too*/
  ${PostItemWrapper} & {
    transition: color 0.3s;
  }
  ${PostItemWrapper}:hover & {
    color: var(--menuHover);
  }
  /*transition: 0.3s;
  &:hover {
    color: var(--postHover);
  }*/
`

export const PostItemDate = styled.time`
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 2px;
`

export const PostItemTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .085rem;
  margin: 0.2rem 0 0.5rem !important;
`

export const PostItemDescription = styled.p`
  //text-align: justify !important;
  font-size: 1.2rem;
  font-weight: 450;
  line-height: 1.2;
  color: var(--postDescription);
  padding-bottom: 0.3rem !important;
`