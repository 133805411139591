import styled from 'styled-components'
import media from "styled-media-query"

import * as V from '../../styles/variables'
import * as T from '../../styles/typography'

export const BlogList = styled.section`
  display: block;
  margin: auto;
  max-width: 75rem;
  padding: 2rem 5rem;
  &:not(:first-child) {
    margin-top: ${V.Space.xxs};
  }

  ${media.lessThan("medium")`
    padding: 0rem 15rem 2rem 1rem;
  `}
`

export const AllPostsLinkWrapper = styled.div`
  //width: 50%;
  //margin-left: 25%;
  &:not(:last-child) {
    margin-bottom: ${V.Space.default};
  }

  ${media.lessThan("medium")`
    margin-left: .1rem;
  `}
`

export const TitleWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-bottom: ${V.Space.default};
  }
`

export const Title = styled.h2`
  ${T.Heading2}
  &:not(:last-child) {
    margin-bottom: ${V.Space.sm};
  }
  &:before {
    content: "★";
    margin-right: .75rem;
    font-family: "Source Code Pro", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    color: var(--greetings);
    font-size: 1.25rem;
    position: relative;
    top: -0.2875rem;
  }
  &:after {
    content: "";
    display: block;
    height: 1px;
    max-width: 17.5rem;
    width: 100%;
    background-color: var(--menuHover);
    position: relative;
    top: -2.1rem;
    margin-left: 7.5rem;

    ${media.lessThan("medium")`
      top: -1.9rem;
    `}
  }

  ${media.lessThan("medium")`
    margin-left: .1rem;
  `}
`

/*export const Nav = styled.nav`
  &:not(:last-child) {
    margin-bottom: ${V.Space.default};
  }
`*/

export const Nav = styled.nav`

  &:not(:last-child) {
    margin-bottom: ${V.Space.default};
  }

  body#grid & {
    background-color: var(--borders);
    border-bottom: 1px solid var(--borders);
    display: grid;
    grid-area: posts;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }

  ${media.lessThan("medium")`
    margin-left: .1rem;
  `}
`