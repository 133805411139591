import React from 'react'
import ReactGA from 'react-ga'

import ButtonLink from '../components/ButtonLink'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogList from '../components/BlogList'
import Author from '../components/Author'

import { IntroBioContent } from '../styles/styledindex'

const trackLanguageClick = () => {
  ReactGA.event({
    category: 'Home',
    action: 'click',
    label: `Home - Go to english`
  })
}

const IndexPage = () => {
  return (
    <Layout>
      <SEO title='Home'
           description='Site pessoal de um Desenvolvedor e Consulto SAP ABAP apaixonado por criar coisas e compartilhar boas idéias.'
      />
      <IntroBioContent>
        <ButtonLink
          to="/en/"
          title="Ver em inglês" 
          onClick={() => trackLanguageClick()} 
          className="botaoIntro">
          View in english
        </ButtonLink>
        <br />
        <Author />
      </IntroBioContent>
      <BlogList />
    </Layout>
  )
}

export default IndexPage