import React from "react"
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'

import getThemeColor from "../../utils/getThemeColor"
import * as S from './styled'

const trackClick = ({ item, label }) => {
  ReactGA.event({
    category: 'Blog',
    action: 'click',
    label: `${label || 'Blog List'} - Go to ${item}`
  })
}

const PostItem = ({slug, category, background, date, timeToRead, title, description, author}) => (
  <S.PostItemLink 
    to={slug}
    cover
    direction="right"
    bg={getThemeColor()}
    duration={.5}
    onClick={() => trackClick(title)}
  >
    <S.PostItemWrapper>
      <S.PostItemTag background={background}>{category}</S.PostItemTag>
      <S.PostItemInfo>
        <S.PostItemDate>{date}</S.PostItemDate>
        <S.PostItemTitle>
          {title}
        </S.PostItemTitle>
        <S.PostItemDescription>
          {description}
        </S.PostItemDescription>
        <S.PostItemDate>{author} <S.PostClock /> {timeToRead} min de leitura</S.PostItemDate>
      </S.PostItemInfo>
    </S.PostItemWrapper>
  </S.PostItemLink>
)

PostItem.propTypes = {
  slug:         PropTypes.string.isRequired,
  background:   PropTypes.string,
  category:     PropTypes.string.isRequired,
  date:         PropTypes.string.isRequired,
  timeToRead:   PropTypes.string.isRequired,
  title:        PropTypes.string.isRequired,
  description:  PropTypes.string.isRequired,
}

export default PostItem